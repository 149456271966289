<template>
  <div class="row relative">
    <div class="col-md-12" v-if="loading">
      <div class="row mt-5 pt-5">
        <div class="col-md-12" v-loading="loading"></div>
      </div>
    </div>

    <div class="col-md-12 my-4" v-if="!loading">
      <div class="d-flex justify-content-end">
        <div class="form_contain">
          <el-input
            placeholder="search user"
            class="search_item"
            v-model="search"
            @input="searchSenderIDs"
            :clearable="true"
            :size="'large'">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
      </div>
    </div>

    <div class="col-md-12" v-if="!loading">
      <el-table
        ref="senderIDTable"
        :data="displayData"
        :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }"
        style="width: 100vw; overflow-x: auto !important"
        :empty-text="'No Data Available'"
        height="55vh"
        v-loading="tableLoading"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="Phone Number" prop="phone" width="150">
        </el-table-column>
        <el-table-column prop="sender_id" label="Sender ID" width="150">
        </el-table-column>
        <el-table-column prop="purpose" label="Purpose" width="150">
        </el-table-column>
        <el-table-column prop="status" label="Status" width="150">
          <template slot-scope="scope">
            <div class="__status_wrapper">
              <button
                class="status_text"
                :class="statusTextClasss(scope.row.status)">
                {{ statusText(scope.row.status) }}
              </button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Time Created" width="200">
          <template slot-scope="scope">
            <span>
              {{ moment(scope.row.created_at).format("DD MMM YYYY hh:mm A") }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Time Updated" width="200">
          <template slot-scope="scope">
            <span>
              {{ moment(scope.row.updated_at).format("DD MMM YYYY hh:mm A") }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            !$route.path.includes('autoApproved') &&
            !$route.path.includes('pending')
          "
          prop="approved_by"
          label="Approved By"
          width="150">
        </el-table-column>
        <el-table-column label="Action" width="200">
          <template slot-scope="scope">
            <button
              id="enter__account"
              class="approve"
              @click="approve(scope)"
              v-if="showApprovedButton">
              Approve
            </button>
            <button
              id="enter__account"
              class="decline"
              @click="disapprove(scope)"
              v-if="showDeclineButton">
              Decline
            </button>
            <button
              id="enter__account"
              class="reserve"
              @click="reserve(scope)"
              v-if="
                (showReserveButton,
                $route.path.includes('approved') ||
                  $route.path.includes('autoApproved'))
              ">
              Reserve
            </button>
            <button
              id="enter__account"
              class="monitor"
              @click="monitor(scope)"
              v-if="showPendingUserVerification">
              Hold
            </button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      v-if="!loading"
      class="col-md-12 mb-4"
      style="text-align: center; margin-top: 2em">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :total="total">
      </el-pagination>
    </div>
    <ActionModal
      ref="actionModal"
      :payload="actionModalPayload"
      @close="closeModal"
      @needsToRefresh="fetchSenderIDsFromStore" />

    <el-dialog
      title="Confirm Reserve Sender ID"
      :visible.sync="showReserveID"
      width="40%">
      <p>Are you sure you want to reserve this Sender Id?</p>
      <p>
        Sender ID:
        <strong
          ><em>{{ reserveIdData.sender_id }}</em></strong
        >
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showReserveID = false"
          >Cancel</el-button
        >
        <el-button type="danger" @click="submitAction" :loading="buttonLoading"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <transition name="el-fade-in-linear">
      <userMultiAction
        v-if="multiSelectActive"
        @update:visible="cancelMultiSelect"
        :multiSelectedData="multiSelectedData"></userMultiAction>
    </transition>
  </div>
</template>
<script>
  import $ from "jquery";
  import store from "@/state/store.js";
  import moment from "moment";
  import _ from "lodash";
  import Storage from "@/services/storage.js";
  import userMultiAction from "./userMultiAction.vue";
  export default {
    name: "",
    components: {
      userMultiAction,
    },
    props: {
      parent: {
        type: Object,
        required: true,
      },
      theoBotMode: {
        type: Boolean,
        default: false,
      },
      dateTimeTheoBot: {
        type: Array,
        default: () => [],
        required: false,
      },
    },
    data() {
      return {
        dataPayload: {},
        from: "",
        search: "",
        actionModalPayload: {},
        page: 1,
        pageSize: 50,
        total: 0,
        search: "",
        receivedData: [],
        tableLoading: false,
        autoApprovedTableCount: 0,
        showReserveID: false,
        reserveIdData: {},
        buttonLoading: false,
        multiSelectActive: false,
        multiSelectedData: [],
      };
    },
    methods: {
      handleCurrentChange(val) {
        this.tableLoading = true;
        store
          .dispatch("senderid/adminSenderIDs", {
            page: val,
            status: this.parent.status,
          })
          .then((data) => {
            this.receivedData = data.data;
            if (this.parent.from == "autoApproved") {
              this.receivedData = this.receivedData.filter((item) => {
                return item.approved_by == "SYSTEM";
              });
              return;
            }
            this.page = val;
          })
          .finally(() => {
            this.tableLoading = false;
          });
      },
      openActionModal() {
        let element = this.$refs.actionModal.$el;
        $(element).modal("show");
      },
      closeModal() {
        let element = this.$refs.actionModal.$el;
        $(element).modal("hide");
      },
      approve(message) {
        this.actionModalPayload = {
          message: "Are you sure you want to approve this sender name?",
          actionMessage: "Approve",
          data: message.row,
          action: "senderID",
          status: 1,
          senderType: this.parent.from,
          index: message.$index,
        };
        this.openActionModal();
      },
      disapprove(message) {
        this.actionModalPayload = {
          message: "Are you sure you want to decline this sender name?",
          actionMessage: "Decline",
          data: message.row,
          status: 2,
          action: "senderID",
          senderType: this.parent.from,
          index: message.$index,
        };
        this.openActionModal();
      },
      monitor(message) {
        this.actionModalPayload = {
          message: "Are you sure you want to monitor this sender name?",
          actionMessage: "Monitor",
          data: message.row,
          status: 3,
          senderType: this.parent.from,
          action: "senderID",
          index: message.$index,
        };
        this.openActionModal();
      },
      statusText(status) {
        if (status == "1") return "Approved";
        if (status == "0") return "Pending";
        if (status == "2") return "Declined";
        if (status == "3") return "On Hold";
      },
      statusTextClasss(status) {
        return [
          (status == "1" && "approved") ||
            (status == "0" && "pending") ||
            (status == "2" && "declined") ||
            (status == "3" && "monitor"),
        ];
      },
      searchSenderIDs: _.debounce(function () {
        if (this.search !== "") {
          this.tableLoading = true;
          let status =
            this.parent.status == "Pending"
              ? 0
              : this.parent.status == "Approved"
              ? 1
              : this.parent.status == "Declined"
              ? 2
              : this.parent.status == "On_Hold"
              ? 3
              : this.parent.status === "auto-approved"
              ? 4
              : "";
          let payload = {
            status: status,
            search: this.search,
          };
          store
            .dispatch("senderid/searchSenderIdAdmin", payload)
            .then((data) => {
              this.receivedData = data.data.data;
              this.total = data.data.data.length;
            })
            .catch(() => {
              this.$message({
                type: "error",
                message: "There was an issue searching for this Sender ID",
              });
            })
            .finally(() => {
              this.tableLoading = false;
            });
        }
        // Hit your endpoint here using this.searchTerm as the search query.
      }, 700),
      reserve(scope) {
        this.showReserveID = true;
        this.reserveIdData = { ...scope.row };
      },
      submitAction() {
        let createBy = new Storage();
        let payload = {
          sender_id: this.reserveIdData.sender_id,
          user_id: this.reserveIdData.user_id,
          created_by: createBy.getSavedState("auth.admin").user_id,
        };
        return store
          .dispatch("senderid/addReservedSenderIDs", payload)
          .then(() => {
            this.showReserveID = false;
            this.$message({
              type: "success",
              message: "Sender ID successfully Reserved",
            });
            store.dispatch("senderid/adminSenderIDs", payload).then(() => {
              this.fetchSenderIDsFromStore();
            });
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "There was an issue reserving this Sender ID",
            });
          });
      },
      fetchSenderIDsFromStore() {
        this.tableLoading = true;
        if (this.$props.theoBotMode) {
          store
            .dispatch("admin/reporting/fetchTheoBotSenderIDs", {
              page: this.page,
              status: this.parent.status,
              date: this.$props.dateTimeTheoBot,
            })
            .then((data) => {
              this.receivedData = data.data;
              this.total = data.total;
              this.autoApprovedTableCount = data.count_auto_approved;
            })
            .finally(() => {
              this.tableLoading = false;
            });
          return;
        }
        // Fetch sender ids from admin normally
        store
          .dispatch("senderid/adminSenderIDs", {
            page: this.page,
            status: this.parent.status,
          })
          .then((data) => {
            this.receivedData = data.data;
            this.total = data.total;
            this.autoApprovedTableCount = data.count_auto_approved;
          })
          .finally(() => {
            this.tableLoading = false;
          });
      },
      handleSelectionChange(val) {
        if (val.length == 0) {
          this.multiSelectActive = false;
          return;
        }
        this.multiSelectActive = true;
        this.multiSelectedData = val;
      },
      cancelMultiSelect($event) {
        this.multiSelectActive = false;
        this.multiSelectedData = [];
        this.$refs.senderIDTable.clearSelection();
        if ($event) {
          this.fetchSenderIDsFromStore();
        }
      },
    },
    computed: {
      searchingTableData() {
        if (!this.search) {
          this.total = this.tableData.length;
          return this.tableData;
        }
        this.page = 1;
        return this.tableData.filter(
          (data) =>
            (data.name &&
              data.name.toLowerCase().includes(this.search.toLowerCase())) ||
            data.reservedIDs.toLowerCase().includes(this.search.toLowerCase())
        );
      },
      tableData() {
        return store.getters["senderid/" + this.parent.storeDataName];
      },
      loading() {
        return store.getters["senderid/loading"];
      },
      showApprovedButton() {
        return this.$route.path == "/admin/sender_ids/approved" ? false : true;
      },
      showAutoApprovedButton() {
        return this.$route.path == "/admin/sender_ids/autoApproved"
          ? false
          : true;
      },
      showDeclineButton() {
        return this.$route.path == "/admin/sender_ids/declined" ? false : true;
      },
      showPendingUserVerification() {
        return this.$route.path == "/admin/sender_ids/pendingUserVerification"
          ? false
          : true;
      },
      showReserveButton() {
        return this.$route.path == "/admin/sender_ids/ReservedSenderIDs"
          ? false
          : true;
      },
      displayData() {
        if (this.parent.from == "autoApproved") {
          this.total = this.autoApprovedTableCount;
          this.receivedData = this.receivedData.filter((item) => {
            return item.approved_by == "SYSTEM";
          });
          return this.receivedData;
        }
        return this.receivedData;
      },
    },
    mounted() {
      this.tableLoading = true;
      if (this.$props.theoBotMode) {
        return;
      }
      store
        .dispatch("senderid/adminSenderIDs", {
          page: 1,
          status: this.parent.status,
        })
        .then((data) => {
          this.receivedData = data.data;
          this.total = data.total;
          this.page = 1;
          this.autoApprovedTableCount = data.count_auto_approved;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    watch: {
      search: function (val) {
        if (val == "") {
          this.tableLoading = true;
          store
            .dispatch("senderid/adminSenderIDs", {
              page: 1,
              status: this.parent.status,
            })
            .then((data) => {
              this.receivedData = data.data;
              this.total = data.total;
              this.page = 1;
              this.autoApprovedTableCount = data.count_auto_approved;
            })
            .finally(() => {
              this.tableLoading = false;
            });
        }
      },
      multiSelectActive: function (val) {
        if (val == false) {
        }
      },
    },
    watch: {
      dateTimeTheoBot: {
        handler() {
          this.fetchSenderIDsFromStore();
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .status_text {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    width: 70px;
    height: 27px;
    border-radius: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    outline: none;
    border: none;
  }

  .approved {
    color: #36d962;
    background: rgba(54, 217, 98, 0.1);
  }

  .declined {
    color: #ee6463;
    background: rgba(238, 100, 99, 0.1);
  }

  .reserve {
    color: #00e4d8 !important;
    background: rgba(238, 100, 99, 0.1);
  }

  .monitor {
    color: #f7921c;
    background: rgba(247, 146, 28, 0.1);
  }

  .pending {
    color: #575761;
    background: rgba(87, 87, 97, 0.1);
  }

  .approve {
    color: #36d962 !important;
  }

  .decline {
    color: #ee6463 !important;
  }

  .monitor {
    color: #f7921c !important;
  }

  .__dropdown_btn {
    background-color: transparent;
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    text-align: left;
  }

  #enter__account {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    color: #f7921c;
    outline: none;
    border: none;
    background-color: transparent;
  }

  #__edit {
    outline: none;
    border: none;
    background: transparent;
    margin-left: 0.5em;
    width: 10px !important;
    padding: 0px;
  }
</style>
